<template>
  <b-modal
    ref="import-transfer-stock-slip"
    hide-footer
    :title="titleExcel"
    id="import-transfer-stock-slip"
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <span> <b>Bước 2:</b> Tiến hành Import </span>
      <b-row>
        <b-col>
          <UploadFileMulter
            v-on:send-file="getDataFromExcel"
            v-on:clear-file="clearFileExcel"
            :cmdUrl="'stock-slips/import-transfer-stock-slip/'"
          />
          <span v-if="fileName">Tên file: <b>{{ fileName }}</b>
          </span>
        </b-col>
      </b-row>
      <!-- End input session -->
    </div>
    <div
      class="mt-10"
      v-show="importItems.length !== 0"
    >
      <hr
        class="hr-text"
        data-content="Danh sách phiếu chuyển kho"
        style="font-weight: 600"
      />
      <b-table
        class="myTable"
        caption-top
        responsive
        bordered
        hover
        :fields="fieldStoreTransfers"
        :items="importItems"
        id="my-table"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template #cell(index)="data">
          {{ data.item.index }}
        </template>
        <template #cell(productQuantity)="data">
          {{ data.item.listProduct?.length || '' }}
        </template>
        <template v-slot:cell(listProduct)="row">
          <i
            class="flaticon-eye"
            @click="showModalDetail(row.item)"
            role="button"
          ></i>
        </template>
        <template #cell(quantity)="data">
          {{ setQuantityListProduct(data.item.listProduct) }}
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle ml-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <i
            class="flaticon2-rubbish-bin-delete-button text-danger"
            @click="showDeleteAlert('Xoá phiếu chuyển kho ?', `Xoá phiếu chuyển kho ${row.item.sourceStoreShortName} >> ${row.item.desStoreShortName}`, row.item, confirmDelete)"
            role="button"
          >
          </i>
        </template>
      </b-table>
      <b-col class="row mt-3">
        <b>
          Tổng số:
          {{ importItems.length || 0 }}
        </b>
      </b-col>
      <b-pagination
        class="custom-pagination"
        v-show="importItems.length > perPage"
        v-model="currentPage"
        :total-rows="importItems.length"
        :per-page="perPage"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </div>
    <b-row>
      <b-modal
        v-b-modal.modal-xl
        ref="modal-transfer-stock-detail"
        hide-footer
        :title="'Danh sách sản phẩm trong phiếu chuyển từ kho ' + sourceStoreShortName + ' >> ' + desStoreShortName"
        id="modal-transfer-stock-detail"
        size="xl"
      >
        <b-table
          hover
          :items="transferDetail"
          :fields="fieldTransferDetailComputed"
          :per-page="perPage"
          :current-page="currentPageDetail"
          bordered
        >
          <template #cell(index)="row">
            {{ row.item.index }}
          </template>
          <template #cell(productName)="row">
            <span
              v-b-tooltip.hover.right="'Bar code'"
              style="font-size: 11px; font-weight: 700"
            >{{ row.item.barCode }}</span><br />
            <span
              v-b-tooltip.hover.right="'Product code'"
              style="font-size: 12px"
            >{{ row.item.productCode }}</span><br />
            <span v-b-tooltip.hover.right="'Product name'">{{ row.item.productName }}</span>
          </template>
          <template #cell(inStockQuantity)="row">
            {{ convertPrice(row.item?.inStockQuantity) ?? ''}}
          </template>
          <template
            #cell(originalPrice)="row"
            v-if="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
          >
            {{ convertPrice(row.item.originalPrice) }}
          </template>
          <template #cell(imeis)="row">
            <template v-if="checkProductImei(row.item.productType)">
              <b-form-textarea
                v-model="row.item.imeis"
                @change="onEditEmei(row.item)"
              >
              </b-form-textarea>
            </template>
            <template v-else>
            </template>
          </template>
          <template #cell(quantity)="row">
            <b-form-input v-model="row.item.quantity" />
          </template>
          <template #cell(totalAmount)="row">
            <span>{{ convertPrice(catchNum(row.item.quantity) * catchNum(row.item.originalPrice)) }}</span>
          </template>
          <!-- <template #cell(imeis)="row">
            <div >
              <template>
                <b-form-textarea
                  v-for="(imei, index) in row.item.imeis.split(',')"
                  :key="index"
                ><b-form-input v-model="row.item.imeis[index]"></b-form-input><br />
                </b-form-textarea>
              </template>
            </div>
          </template> -->
          <template v-slot:cell(actions)="row">
            <i
              class="flaticon2-rubbish-bin-delete-button text-danger"
              @click="showDeleteAlert('Xoá sản phẩm ?', `Xoá ${row.item.productName} khỏi phiếu chuyển kho từ  ${sourceStoreShortName} >> ${desStoreShortName}`, row.item, confirmDeleteProduct)"
              role="button"
            >
            </i>
          </template>
        </b-table>
        <b-row>
          <b-col fixed>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ transferDetail.length }}
            </p>
          </b-col>
          <b-pagination
            class="custom-pagination"
            v-show="transferDetail.length > perPage"
            v-model="currentPageDetail"
            :total-rows="transferDetail.length"
            :per-page="perPage"
            align="right"
          >
          </b-pagination>
        </b-row>
        <b-button
          style="width: 80px"
          class="mr-2"
          size="sm"
          variant="primary"
          @click="onSubmit"
        >
          Lưu
        </b-button>
        <b-button
          style="width: 80px"
          variant="secondary"
          size="sm"
          @click="hideModalDetail()"
        >
          <strong>Đóng</strong>
        </b-button>
      </b-modal>
    </b-row>
    <b-button
      style="width: 80px"
      class="mt-6 mr-2"
      v-if="importItems.length && !isBusy"
      variant="primary"
      size="sm"
      @click="onCreateTransferThrottle()"
    >
      <strong>Tạo mới</strong>
    </b-button>
    <b-button
      v-if="importItems.length && isBusy"
      style="width: 100px"
      class="mt-6 mr-2"
      variant="primary"
      size="sm"
      disabled
    >
      <b-spinner small></b-spinner>
      Tạo mới
    </b-button>
    <b-button
      class="mt-6"
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModalExcel"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>
<script>
import { BASE_URL } from '@/utils/constants';
import ApiService from '@/core/services/api.service';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import localData from '@/utils/saveDataToLocal';
import { showDeleteAlert } from '@/utils/sweet-alert2.js';
import { DATE_FORMAT } from '@/utils/constants';
import throttle from 'lodash/throttle';
import {
  STATUS_CODE,
  STOCK_SLIP_RELATION_TYPE,
  PRODUCT_TYPE,
} from '@/utils/enum';
import {
  convertPrice,
  makeToastSuccess,
  makeToastFaile,
  catchNum,
  cloneDeep,
} from '@/utils/common';
import {
  formatSpecificDate,
  YEAR_MONTH_DAY_FORMAT,
  formatDate,
} from '@/utils/date';
import { checkPermission } from '../../../utils/saveDataToLocal';

export default {
  name: 'StockSlipImportExcel',
  data() {
    return {
      DATE_FORMAT: DATE_FORMAT,
      currentPageError: 1,
      titleExcel: 'Nhập excel phiếu chuyển kho',
      urlExcel:
        BASE_URL + 'media/upload/store-transfer/Format_StoreTransfer.xlsx',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      importItems: [],
      isBusy: false,
      fileName: '',
      sourceStoreShortName: '',
      desStoreShortName: '',
      currentIndexDetail: null,
      perPage: 10,
      fieldStoreTransfers: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'sourceStoreShortName',
          label: 'Cửa hàng chuyển',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'font-weight-bold',
        },
        {
          key: 'desStoreShortName',
          label: 'Cửa hàng nhận',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'font-weight-bold',
        },
        {
          key: 'productQuantity',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'transferDate',
          label: 'Ngày chuyển kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
        },
        {
          key: 'listProduct',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-center',
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-left',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      transferDetail: [],
      fieldTransferDetail: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
          visible: true,
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
          tdClass: 'text-left',
          visible: true,
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          visible: true,
        },
        {
          key: 'imeis',
          label: 'Imei',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
          visible: true,
        },
        {
          key: 'originalPrice',
          label: 'Đơn giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          visible: checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW'),
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          visible: true,
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          visible: checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW'),
        },
        { key: 'actions', label: '', tdClass: 'text-center', visible: true },
      ],
      currentPageDetail: 1,
    };
  },
  components: {
    UploadFileMulter,
  },
  computed: {
    hasError() {
      const errors = this.importItems.map((item) => item.errors).flat();
      return errors.length > 0;
    },
    fieldTransferDetailComputed() {
      return this.fieldTransferDetail.filter((item) => item.visible);
    },
  },
  methods: {
    catchNum,
    convertPrice,
    showDeleteAlert,
    onEditEmei(row) {
      this.transferDetail = this.transferDetail.map((item) => {
        if (row.index === item.index) {
          const imeiReplace = row.imeis.replace(/(\r\n|\n|\r|\s+)/gm, '');
          const tmp = imeiReplace.split(',').filter((e) => e.trim() !== '');
          return {
            ...row,
            imeis: imeiReplace || '',
            quantity: tmp?.length || 0,
          };
        }
        return item;
      });
    },
    checkProductImei(productType) {
      return productType === PRODUCT_TYPE.PRODUCT_IMEI;
    },
    onSubmit() {
      this.importItems[this.currentIndexDetail].listProduct =
        this.transferDetail;
      makeToastSuccess('Cập nhật thành công');
      this.hideModalDetail();
    },
    showModal() {
      this.$refs['import-transfer-stock-slip'].show();
    },
    showModalDetail(row) {
      const {
        listProduct = [],
        sourceStoreShortName,
        desStoreShortName,
        index,
      } = row;
      this.sourceStoreShortName = sourceStoreShortName;
      this.desStoreShortName = desStoreShortName;
      this.currentIndexDetail = index - 1;
      this.transferDetail = listProduct.map((item, index) => {
        return {
          ...item,
          index: ++index,
        };
      });
      this.$refs[`modal-transfer-stock-detail`].show();
    },
    hideModalDetail() {
      this.$refs[`modal-transfer-stock-detail`].hide();
    },
    confirmDelete(row) {
      const { sourceStoreId, desStoreId } = row;
      const keyDelete = `${sourceStoreId}_${desStoreId}`;
      this.importItems = this.importItems.filter(
        (item) => `${item.sourceStoreId}_${item.desStoreId}` !== keyDelete,
      );
    },
    confirmDeleteProduct(row) {
      this.transferDetail = this.transferDetail.filter(
        (item) => item.index !== row.index,
      );
      this.importItems[this.currentIndexDetail].listProduct =
        this.transferDetail;
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    hideModalExcel() {
      this.$refs['import-transfer-stock-slip'].hide();

      this.clearFileExcel();
    },
    onCreateTransferThrottle: throttle(function () {
      this.onCreateTransferStock();
    }, 2000),
    async onCreateTransferStock() {
      const items = cloneDeep(this.importItems);
      const body = items.map((item) => {
        return {
          ...item,
          transferDate: formatSpecificDate(
            item.transferDate,
            DATE_FORMAT,
            YEAR_MONTH_DAY_FORMAT,
          ),
        };
      });
      if (!this.isBusy) {
        try {
          this.isBusy = true;
          const result = await ApiService.post(
            'stock-slips/transfer-stock-slip',
            body,
          );
          const { message, status } = result.data;
          if (status === STATUS_CODE.SUCCESS) {
            makeToastSuccess(message || 'Thành công');
            const date = formatDate(new Date(), DATE_FORMAT);
            const params = {
              searchFromDate: date,
              relationType: STOCK_SLIP_RELATION_TYPE.PARENT,
            };
            this.$emit('params', params);
            this.hideModalExcel();
          } else {
            makeToastFaile(message || 'Lỗi');
          }
        } catch (err) {
          const { message } = err.response.data;
          makeToastFaile(message || 'Lỗi');
        } finally {
          this.isBusy = false;
        }
      }
    },
    getDataFromExcel(data, fileName) {
      const { rows } = data || {};
      this.fileName = fileName;
      this.importItems = rows?.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          onLoading: false,
        };
      });
      this.onLoadingList = false;
    },
    clearFileExcel() {
      this.importItems = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
    setQuantityListProduct(listProduct = []) {
      return listProduct.reduce((acc, cur) => acc + catchNum(cur.quantity), 0);
    },
  },
};
</script>
    
<style lang="scss">
.import-transfer-stock-slip {
  background-color:aqua table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }

  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}

#b-toaster-top-right {
  user-select: text;
  z-index: 999999999;
}
</style>  